@use 'sass:map';
@use 'variables';

@mixin innerBorder($border_color, $border_size: 1px) {
  box-shadow: 0 0 0 $border_size $border_color inset;
}

// flex 설정
// @include flex(space-between, center); // flex row
// @include flex(space-between, center, column); // flex column
@mixin flex($jc, $ai, $di: row) {
  display: flex;
  align-items: $ai;
  justify-content: $jc;
  @if $di != row {
    flex-direction: $di;
  }
}

@mixin grid($col: 2, $gap: 0) {
  display: grid;
  grid-column: $col;
  grid-template-columns: repeat($col, minmax(0, 1fr));
  gap: $gap;
}

// 스크롤바 숨기고 싶을때
// @include hiddenScroll;
@mixin hiddenScroll {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// 가로로 스냅 스크롤 주고 싶을때
// @include snapScroll(ptr(40));
@mixin snapScroll($offset) {
  padding: 0 $offset;
  overflow: auto hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-margin-left: $offset;
  scroll-margin-right: $offset;
  scroll-padding-left: $offset;
  scroll-padding-right: $offset;
  touch-action: cross-slide-x;

  & > * {
    scroll-snap-align: start;
  }
}

// 가로로 스냅 스크롤 주고 싶을때
// @include snapScrollY(ptr(40));
@mixin snapScrollY($offset) {
  overflow: hidden auto;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  touch-action: cross-slide-y;

  & > * {
    scroll-snap-align: start;
  }
}

// object-fit cover
// @include objectCover;
@mixin objectCover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// 말줄임 처리
// @include lineLimit(1);
@mixin lineLimit($line: 2) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  @if $line == 1 {
    white-space: nowrap;
  }
}

@mixin limitLine($l) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $l;
  overflow: hidden;
}

// 백드롭 블러처리
// @include blur10;
@mixin blur10 {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
@mixin blur30 {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
@mixin blur40 {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
@mixin blur60 {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
}

// 필터 블러처리
// @include filter10;
@mixin filter10 {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
@mixin filter30 {
  -webkit-filter: blur(15px);
  filter: blur(15px);
}
@mixin filter40 {
  -webkit-filter: blur(20px);
  filter: blur(20px);
}
@mixin filter60 {
  -webkit-filter: blur(30px);
  filter: blur(30px);
}

@mixin safeAreaTop {
  padding-top: env(safe-area-inset-top, 0);
  padding-top: contant(safe-area-inset-top, 0);
}

@mixin safeAreaBottom {
  padding-bottom: env(safe-area-inset-bottom, 0);
  padding-bottom: contant(safe-area-inset-bottom, 0);
}

@mixin safeAreaTopPlus($top: 0) {
  padding-top: calc(env(safe-area-inset-top, 0) + #{$top});
  padding-top: calc(contant(safe-area-inset-top, 0) + #{$top});
}

@mixin safeAreaBottomPlus($bottom: 0) {
  padding-bottom: calc(env(safe-area-inset-bottom, 0) + #{$bottom});
  padding-bottom: calc(contant(safe-area-inset-bottom, 0) + #{$bottom});
}

// @deprecated htmlConditioner을 사용해주세요
@mixin themify($theme, $device: false, $lang: false) {
  @if $lang and $device {
    html[theme='#{$theme}'][device='#{$device}'][lang='#{$lang}'] {
      @content;
    }
  } @else if $device {
    html[theme='#{$theme}'][device='#{$device}'] {
      @content;
    }
  } @else if $lang {
    html[theme='#{$theme}'][lang='#{$device}'] {
      @content;
    }
  } @else {
    html[theme='#{$theme}'] {
      @content;
    }
  }
}

@mixin conditionThemify($theme, $device: false, $device_type: false, $lang: false) {
  @if $lang and $device and $device_type {
    html[theme='#{$theme}'][device='#{$device}'][device_type='#{$device_type}'][lang='#{$lang}'] & {
      @content;
    }
  } @else if $lang and $device {
    html[theme='#{$theme}'][device='#{$device}'][lang='#{$lang}'] & {
      @content;
    }
  } @else if $device {
    html[theme='#{$theme}'][device='#{$device}'] & {
      @content;
    }
  } @else if $lang {
    html[theme='#{$theme}'][lang='#{$lang}'] & {
      @content;
    }
  } @else {
    html[theme='#{$theme}'] & {
      @content;
    }
  }
}

@mixin htmlConditioner($theme: false, $device: false, $device_type: false, $lang: false) {
  $map: (
    theme: $theme,
    device: $device,
    device_type: $device_type,
    lang: $lang,
  );

  $assign: '=';
  $selector: 'html';
  @each $key, $value in $map {
    @if $value != false {
      $selector: #{$selector}+ '[#{$key}#{$assign}#{$value}]';
    }
  }

  #{$selector} {
    @content;
  }
}

@mixin allThemify {
  @each $theme, $_ in variables.$themes {
    html[theme='#{$theme}'] & {
      @content;
    }
  }
}
